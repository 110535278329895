import React from "react";
import styles from './FullWidthBackground.module.scss';

function FullWidthBackground({
  children = null,
  bg = null,
  ...props
}) {
  const classNames = [
    bg ? null : styles.hasNoBackground,
    styles.fullWidthBackground
  ].filter(Boolean).join(' ');

  return (
    <div className={classNames} style={{
      backgroundImage: bg ? `url('https://smp.nl${bg}')` : null,
    }} {...props}>{children}</div>
  );
}

export default FullWidthBackground;
