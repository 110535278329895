import React, {
  cloneElement,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from 'react';
import {Link, matchPath, useLocation, useHistory, NavLink} from 'react-router-dom';
import styles from './ScrollableSite.module.scss';
import Home from '../Home';
import AboutUsPage from '../AboutUsPage';
import ContactPage from "../ContactPage";
import LibraryPage from "../LibraryPage";
import ShowreelPage from "../ShowreelPage";
import PortfolioCategoryPage from "../PortfolioCategoriesPage";
import debounce from 'lodash/debounce';
import MainNav from "../MainNav";
import normalizeWheel from 'normalize-wheel';
import useTranslations from "../../hooks/useTranslations";
import MobileNav from "../MobileNav";
import { store } from '../App/store';
import useSeo from "../../hooks/useSeo";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function ScrollableRoute({
  children, path, name, nav, activeSlide, slideDirection, ...props
}) {
  const location = useLocation();
  const history = useHistory();

  let matchedRoutePath = null;
  const match = props.i18n.reduce((res, langVariant) => {
    if (res) {
      return res;
    }
    const m = matchPath(location.pathname, {
      path: langVariant.route,
      exact: true,
      strict: false,
    });
    if (m) {
      matchedRoutePath = langVariant.route;
    }
    return m;
  }, null);

  if (match) {
    nav(name);
  }

  return cloneElement(children, {
    match,
    location,
    history,
    active: activeSlide === name,
    activeSlide,
    slideDirection,
    path,
    isInView: location.pathname === matchedRoutePath,
  });
}


function ScrollableSite({setActivePath, ...props}) {
  // Used so that when the page loads & this element should not be visible, it's not.
  const [isInitiallyHidden, setIsInitiallyHidden] = useState(true);
  // Used to keep track of what slide is/was active.
  // Because these slides are active even when the router doesn't match, we don't use
  // the <Route> element to show/hide the elements.
  const [activeSlide, setActiveSlide] = useState(null);
  // Used for the indicator at the bottom of the screen
  const [paginationProps, setPaginationProps] = useState({
    activeIndex: -1, prevLink: null, nextLink: null, direction: 1
  });
  // Used to keep track of window width; re-nav to nearest page on resize.
  const [width, setWidth] = useState(window.innerWidth);
  // Used as a buffer when there are many mouse scroll events
  const scrollBuffer = useRef([]);
  const scrollAnimationFrame = useRef(null);
  // Used for the X position of the large slider
  const [scrollX, setScrollX] = useState({value: 0, className: ''});
  const scrollXRef = useRef(0);
  // Used for the Y position when on mobile
  const [scrollY, setScrollY] = useState({value: 0});
  // Routes on the slider that can be navigated to.
  const routes = useRef(new Map());
  const lastScrollPositionNotScrolledManuallyTo = useRef(0);
  const __ = useTranslations();
  const { state } = useContext(store);
  const [seo, setSeo] = useState(null);

  const homeRef = useRef();
  const aboutRef = useRef();
  const showreelRef = useRef();
  const portfolioRef = useRef();
  const libraryRef = useRef();
  const contactRef = useRef();
  const urls = useTranslatableUrls();

  const slideRefs = [homeRef, aboutRef, showreelRef, portfolioRef, libraryRef, contactRef];

  /*
   * This is a list of "pages" that are in the main slider. They're not separate sub-pages.
   * These slides are semi-hardcoded here.
   * The 'path' property should not be used anymore, but it's been kept in a deprecated state
   * just to be sure--it can probably be removed.
   * The 'seo' property is a fallback value in case a translated route does not have
   * a value.
   * This array is mapped over. It then receives a property named 'i18n' with
   * internationalized URLs and SEO properties.
   * This array is looped over in the app to find what page/language variant the user
   * is looking at.
   * This variable is a ref, although that probably doesn't actually help against
   * re-renders.
   */
  routes.current = new Map([
    {name: 'home', path: '/', seo: {
      title: 'Home',
    }},
    {name: 'about', path: '/about', seo: {
      title: 'About us',
    }},
    {name: 'showreel', path: '/showreel', seo: {
      title: 'Showreel',
    }},
    {name: 'portfolio', path: '/portfolio', seo: {
      title: 'Portfolio',
    }},
    {name: 'library', path: '/library', seo: {
      title: 'Library',
    }},
    {name: 'contact', path: '/contact', seo: {
      title: 'Contact',
    }},
  ].map((entry, i) => {
    const i18n = Object.values(state.routes).filter(({slug}) => {
      if (slug === 'portfoliocategorien' && entry.name === 'portfolio') {
        return true;
      }
      if (slug === 'over_ons' && entry.name === 'about') {
        return true;
      }
      return (slug === entry.name);
    });
    return [
      entry.name,
      {
        ...entry,
        key: entry.name,
        path: `${process.env.PUBLIC_URL&&''}${entry.path}`,
        // To prevent calling useRef() in a loop, we get existing refs from an array.
        ref: slideRefs[i],
        i18n,
        getByLang(lang) {
          const merged = Object.assign({
            route: entry.path,
            name: entry.name,
            slug: entry.name,
            lang: undefined,
            id: undefined,
          }, i18n.find(entry => entry.lang === parseInt(lang, 10)) || {});

          // Merge the seo-sub-object with the defaults & the found (if any) values.
          merged.seo = Object.assign({}, entry.seo, merged.seo || {});

          return merged;
        }
      }
    ];
  }));

  const location = useLocation();
  const history = useHistory();
  const debouncedSnapToNearest = useCallback(debounce((direction = 0) => {
      if (!routes.current) {
        console.error('No slides/routes known');
        return;
      }
      // Get the position of the scrollbar.
      const scrollOffset = -scrollXRef.current || 0;
      let smallestDelta = Infinity;
      let nearest = null;
      // Find the offsets of the route elements.
      routes.current.forEach((route) => {
        const elementOffset = route.ref.current?.offsetLeft;
        if (typeof elementOffset !== "number") {
          return;
        }
        const delta = Math.abs(elementOffset - scrollOffset);
        if (delta <= smallestDelta ) {
          if (direction > 0 && elementOffset > scrollOffset) {
            nearest = route;
            smallestDelta = delta;
          } else if (direction < 0 && elementOffset < scrollOffset) {
            smallestDelta = delta;
            nearest = route;
          } else if (direction === 0) {
            smallestDelta = delta;
            nearest = route;
          }
        }
        if (delta <= smallestDelta /*&& elementOffset >= scrollOffset*/) {
          //smallestDelta = delta;
          //nearest = route;
        }
      });
      if (nearest) {
        setActiveSlide((current) => {
          if (current !== nearest.name) {
            requestAnimationFrame(() => {
              history.push(nearest.getByLang(state.lang).route);
            });
          }
          // Dirty way to force re-navigation.
          return null;
        });
      }
    }, 250), [routes, scrollXRef, history, state.lang]);

  useEffect(() => {
    // Location has changed, check if anything is active.
    const firstMatch = Array.from(routes.current.values()).reduce((res, route) => {
      if (res) {
        return res;
      }
      // We might have navigated to a translated route. Find out what language variant.
      const langId = route.i18n.reduce((langId, i18nRoute) => {
        if (langId) {
          return langId;
        }
        return matchPath(location.pathname, {
          path: i18nRoute.route, // check if for example nl/foobar matches.
          exact: true,
          strict: false,
        }) ? i18nRoute.lang : undefined; // if it does, return the language id.
      }, undefined);
      // Get the translated route path, title, etc.
      const i18n = langId && route.getByLang(langId);

      // Return either the translated route, the fallback hardcoded values or null.
      const match = i18n || matchPath(location.pathname, {
        path: route.path,
        exact: true,
        strict: false,
      });
      // Return match or undefined.
      return match ? match : undefined;
    }, undefined);

    // Can be either a translated path (likely) or a fallback deprecated hardcoded value.
    setActivePath(firstMatch ? firstMatch.path : null);

    // Overwrite SEO values such as title.
    setSeo(firstMatch?.seo || null);

    // If a match is found, set the slider to visible.
    if (firstMatch) {
      setIsInitiallyHidden(false);
    }
  }, [setActivePath, location, routes, setSeo]);

  const ref = useRef();

  useSeo(seo);

  function onWheel(ev) {

    if (window.matchMedia) {
      // Don't run this function on mobile viewports
      //if(!window.matchMedia('(min-width: 640px)').matches) {
      if(!window.matchMedia('(min-width: 1024px)').matches) {
        return;
      }
    }

    if (activeSlide === 'portfolio') {
      // return; // Disable scrolling on this page.
    }

    const normalized = normalizeWheel(ev);
    const largestDelta = Math.abs(normalized.pixelX) > Math.abs(normalized.pixelY)
      ? normalized.pixelX
      : normalized.pixelY;
    scrollBuffer.current.push(largestDelta);
    const min = 0;
    const max = routes.current.get('contact').ref.current?.offsetLeft || Infinity;
    if (!scrollAnimationFrame.current) {
      scrollAnimationFrame.current = window.requestAnimationFrame(() => {
        scrollAnimationFrame.current = null;
        const delta = scrollBuffer.current.reduce((tot, cur) => tot + cur);
        scrollBuffer.current.splice(0); // empty out array, but not overwrite it.
        // Move scrollable element by this many pixels.
        // When released, snap to position.
        setScrollX(({value}) => {
          const newValue = Math.max(-max, Math.min(-min, value - (delta * .5)));
          scrollXRef.current = newValue;
          return {
            value: newValue,
            animated: false,
          };
        });
        debouncedSnapToNearest(delta >= 0 ? 1 : -1);
      });
    }
  }

  useEffect(() => {
    // When active slide changes...
    if (!activeSlide) {
      return;
    }
    const route = routes.current.get(activeSlide);
    if (!route || !route.ref.current) {
      console.error('Could not navigate to', activeSlide);
      return;
    }

    //if(!window.matchMedia('(min-width: 640px)').matches) {
    if(!window.matchMedia('(min-width: 1024px)').matches) {
      // Do mobile version.
      const offset = route.ref.current.offsetTop;
      lastScrollPositionNotScrolledManuallyTo.current = scrollXRef.current;
      setScrollY({
        value: offset,
      });
      setScrollX({
        value: 0,
        animated: false
      });
      return;
    }

    // Get DOM element & offset.
    const offset = route.ref.current.offsetLeft;
    // Set offset of parent to this offset, negative.
    scrollXRef.current = -offset;
    lastScrollPositionNotScrolledManuallyTo.current = scrollXRef.current;
    setScrollX({
      value: -offset,
      animated: true
    });
    setScrollY({
      value: 0,
    });
  }, [routes, activeSlide]);

  useEffect(() => {
    // Images might load slower than some other dependencies of this component.
    // This probably leads to the offsetTop being not high enough, because after
    // animating, the viewport is not at the expected section.
    // As a dirty hack, this re-sets the offsetTop after window load, which
    // might be jarring.
    if(window.matchMedia('(min-width: 1024px)').matches) {
      return;
    }

    const handlePageLoad = () => {

      // Let's wait a frame or so before doing this.
      // Maybe it solves edge cases.
      requestAnimationFrame(function() {
        const route = routes.current.get(activeSlide);
        if (!route || !route.ref.current) {
          return;
        }
        const offset = route.ref.current.offsetTop;
        lastScrollPositionNotScrolledManuallyTo.current = scrollXRef.current;
        setScrollY({
          value: offset,
          animated: false
        });
        setScrollX({
          value: 0,
          animated: false
        });
      });
    };
    window.addEventListener('load', handlePageLoad);
    return () => {
      window.removeEventListener('load', handlePageLoad);
    }
  }, [activeSlide]);

  useEffect(() => {
    //if(!window.matchMedia('(min-width: 640px)').matches) {
    // When page resizes
    const handleResize = () => {
      if(!window.matchMedia('(min-width: 1024px)').matches) {
        return;
      }

      if (activeSlide && Math.abs(window.innerWidth - width) > 10) {
        // Page width was altered, re-navigate to correct slide.
        setWidth(window.innerWidth);
        // Dirty way to force re-navigation.
        setActiveSlide(null);
        requestAnimationFrame(() => {
          setActiveSlide(activeSlide);
        })
      }
    };
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, [width, activeSlide]);

  useEffect(() => {
    // Not using .entries for compatibility
    let activeIndex = -1;
    let prevLink;
    let nextLink;
    let i = -1;

    routes.current.forEach((value, key) => {
      i++;
      if (activeIndex !== -1) {
        // Match was found
        if (activeIndex + 1 === i) {
          nextLink = value.getByLang(state.lang).route;
        }
        // Quit loop
        return;
      }
      if (key === activeSlide) {
        // Found match
        activeIndex = i;
        return;
      }
      prevLink = value.getByLang(state.lang).route;
    });
    if (activeIndex === -1) {
      prevLink = undefined;
    }
    setPaginationProps((prev) => {
      let direction = prev.direction;
      if (activeIndex !== -1) {
        direction = prev.activeIndex <= activeIndex ? 1 : -1;
      }
      return {
        activeIndex,
        prevLink,
        nextLink,
        direction,
      }
    });
  }, [setPaginationProps, activeSlide, routes, state.lang]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (Math.abs(ref.current.parentNode.scrollTop - scrollY.value) < 20) {
      // Don't do anything if insignificant.
      return;
    }

    // Start animation.
    let startTs;
    const time = 1000;
    const el = ref.current.parentNode;
    requestAnimationFrame(function step(ts) {
      if (!startTs) {
        startTs = ts;
      }
      let timeDelta = ts - startTs;
      if (timeDelta > 2000) {
        console.warn('Loop took longer than expected');
        el.scrollTop = scrollY.value;
        return;
      }
      if (scrollY.animated === false) {
        timeDelta = 0;
      }
      const progress = Math.min(timeDelta / time, 1);
      const delta = scrollY.value - el.scrollTop;
      el.scrollTop += (delta * progress);
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    });

    //ref.current.parentNode.scrollTop = scrollY.value;
  }, [scrollY, ref]);

  const routeProps = {
    nav(name) {
      if (activeSlide === name) {
        return;
      }
      requestAnimationFrame(() => {
        setActiveSlide(name);
      });
    },
    ref: null,
    activeSlide,
    slideDirection: paginationProps.direction,
    currentLanguage: state.lang,
  };

  const route = key => routes.current.get(key);
  let transform = null;
  transform = `translateX(${Math.min(scrollX.value)}px) translateZ(0)`;

  const classNames = [
    styles.container,
    styles.fillScreen,
    isInitiallyHidden && styles.initiallyHidden,
    state.pageScrollingEnabled ? styles.isScrollable : styles.isNotScrollable,
  ].filter(Boolean).join(' ');

  const aboutUsSubmenu = useMemo(() => (
    <div>
      <NavLink to={urls('team')}>
        {__('Meet the members')}
      </NavLink>

      <NavLink to={urls('wat_we_doen')}>
        {__('Wat we doen')}
      </NavLink>

      <NavLink to={urls('onze_studio')}>
        {__('Onze studio')}
      </NavLink>
    </div>
  ), [__, urls]);

  const links = Array.from(routes.current.values()).map((route) => {
    // Uppercase name of route.
    let name = route.name[0].toUpperCase() + route.name.slice(1);
    switch(route.name) {
      case 'about':
        name = 'Over ons';
        break;
      case 'portfolio':
        name = 'Ons portfolio';
        break;
      case 'library':
        name = 'SMP Library';
        break;
      default:
        break;
    }
    return [
      <Link to={route.getByLang(state.lang).route} key={route.name}>{__(name)}</Link>,
      <NavLink to={route.getByLang(state.lang).route} key={route.name}>{__(name)}</NavLink>
    ];
  });

  return (
    <div className={classNames}>
      <MainNav activeIndex={paginationProps.activeIndex}
        nextLink={paginationProps.nextLink} prevLink={paginationProps.prevLink}>
        {links.map(l => l[0])}
      </MainNav>
      <MobileNav activeIndex={paginationProps.activeIndex} aboutUsSubmenu={aboutUsSubmenu}>
        {links.map(l => l[1])}
      </MobileNav>
      <div className={styles.offset} ref={ref} style={{
        transform: transform,
        transitionDuration: scrollX.animated ? null : '0s',
      }} onWheel={onWheel}>
        <ScrollableRoute {...route('home')} {...routeProps}>
          <Home ref={route('home').ref} />
        </ScrollableRoute>
        <ScrollableRoute {...route('about')} {...routeProps}>
          <AboutUsPage ref={route('about').ref}
            activePageIsAfterThis={paginationProps.activeIndex > 1} />
        </ScrollableRoute>
        <ScrollableRoute {...route('showreel')} {...routeProps}>
          <ShowreelPage ref={route('showreel').ref}
            activePageIsAfterThis={paginationProps.activeIndex > 2} />
        </ScrollableRoute>
        <ScrollableRoute {...route('portfolio')} {...routeProps}>
          <PortfolioCategoryPage ref={route('portfolio').ref} />
        </ScrollableRoute>
        <ScrollableRoute {...route('library')} {...routeProps}>
          <LibraryPage ref={route('library').ref} />
        </ScrollableRoute>
        <ScrollableRoute {...route('contact')} {...routeProps}>
          <ContactPage ref={route('contact').ref} />
        </ScrollableRoute>
      </div>
    </div>
  );

}

export default ScrollableSite;
