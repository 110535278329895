import React from 'react';
import styles from './PortfolioPage.module.scss';
import Button from "../Button";

function PortfolioItemButton({children, className, ...props}) {

  const classNames = [
    className,
    styles.button,
  ].filter(Boolean).join(' ');

  return (
    <Button className={classNames} {...props}>
      {children}
    </Button>
  );

}

export default PortfolioItemButton;
