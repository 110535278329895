import React, {useContext, useMemo} from "react";
import {useLocation} from 'react-router-dom';
import {store} from "../components/App/store";

const history = { current: {} };
const defaultHistory = {
  type: 'other',
  path: null,
  slider: null,
  portfolio: null,
};

function useHistorySaver() {
  const location = useLocation();
  const pathName = location.pathname;
  const { state } = useContext(store);

  /*const sliderRoutes = [
    '/',
    '/about',
    '/showreel',
    '/portfolio',
    '/library',
    '/contact',
  ];*/
  const whitelist = [
    'home', 'over_ons', 'showreel', 'portfoliocategorien', 'library', 'contact',
  ];
  const sliderRoutes = Object.values(state.routes).filter((route) => {
    return whitelist.includes(route.slug);
  }).map((route) => {
    return route.route;
  });

  const subPageOfPortolio = Object.values(state.routes).filter((route) => {
    return route.slug === 'portfoliocategorien';
  }).map((route) => {
    return route.route;
  });

  return useMemo(() => {
    const previous = Object.assign({}, defaultHistory, history?.current || {});
    const current = history.current;

    // If no change, abort early.
    if (previous.path === pathName) {
      return previous;
    }

    // Update new values.
    current.path = pathName;
    current.type = defaultHistory.type;

    if (sliderRoutes.includes(pathName)) {
      current.slider = pathName;
      current.type = 'slider';
    } else if (subPageOfPortolio.some(
      (route) => pathName.startsWith(route) && pathName.length > route.length)
    ) {
      current.portfolio = pathName;
      current.type = 'portfolio';
    }
    return previous;
  }, [pathName, sliderRoutes, subPageOfPortolio]);
}

function Component() {
  useHistorySaver();
  return (<></>);
}

export default useHistorySaver;
export { Component };
