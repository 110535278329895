import React from "react";

function ContactContent({content, ...props}) {
  return (
      <div>
        <h1>{content.title}</h1>
        <div dangerouslySetInnerHTML={{__html: content.txt}} />
      </div>
  );
}

export default ContactContent;
