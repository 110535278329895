import React, {useEffect, useRef, useState} from "react";
import styles from './Video.module.scss';
import { withRouter } from "react-router-dom";

function VideoInner({
  src,
  srcs,
  style = {},
  children = null,
  className = '',
  ratio = '16x9',
  onClick,
  poster = null,
  ...props
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isNearBeginning, setIsNearBeginning] = useState(true);
  const videoRef = useRef();

  const ratioClass = (ratio && styles[`ratio${ratio}`]) || null;
  const paddingBottom = `calc(${ratio.split('x')[1] || 1} / ${ratio.split('x')[0] || 1} * 100%)`;

  const classNames = [
    styles.videoContainer,
    styles.ratio,
    ratioClass,
    isPlaying ? styles.isPlaying : styles.isPaused,
    className,
    isNearBeginning ? styles.isNearBeginning : styles.isNotNearBeginning,
  ].filter(Boolean).join(' ');

  function toggle({currentTarget: target}) {
    if (target.paused) {
      target.play();
    } else {
      target.pause();
    }
  }

  useEffect(() => {
    if (!(videoRef.current instanceof HTMLVideoElement)) {
      return;
    }

    const el = videoRef.current;
    const fn = (ev) => {
      if (el.currentTime <= 1) {
        setIsNearBeginning(true);
      } else {
        setIsNearBeginning(false);
      }
    };

    el.addEventListener('timeupdate', fn);
    return () => {
      el.removeEventListener('timeupdate', fn);
    }
  });

  useEffect(() => {
    if (!(videoRef.current instanceof HTMLVideoElement)) {
      return;
    }

    // Listen to nav events
    const unbind = props.history.listen((newLocation) => {
      setIsPlaying(false);
      videoRef.current.pause();
    });

    return () => {
      unbind();
    }
  });

  return (
    <div className={classNames}
      style={{
        ...style,
        paddingBottom: ratioClass ? null : paddingBottom,
      }}>
      {/* poster={poster} */}
      <video
        src={src} ref={videoRef}
        className={styles.video} onClick={onClick || toggle}
        onPlaying={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)}>
        {(srcs || []).map(([src, mimeType = 'video/mp4']) => (
          <source key={`${mimeType}--${src}`} src={src} type={mimeType} />
        ))}
      </video>
      <div className={styles.fakePoster} style={{
        backgroundImage: `url('${poster}')`,
      }} />
    </div>
  );
}

const Video = withRouter(VideoInner);

export default Video;
