import React from "react";
import styles from './Contact.module.scss';

function ContactDetail({content, ...props}) {
  return (
      <div className={styles.contactDetail} dangerouslySetInnerHTML={{__html: content.txt}} />
  );
}

export default ContactDetail;
