import React from "react";
import styles from './ContactTagline.module.scss';

function ContactTagline({prefix, children, ...props}) {
  return (
    <div className={styles.Tagline}>
      <h1>{prefix}</h1>
      <div className={styles.Container}>
        <h2>{children}</h2>
        <h2 className={styles.Clone}>{children}</h2>
      </div>
    </div>
  );
}

export default ContactTagline;
