import React, {forwardRef, useContext, useState, useMemo} from "react";
import styles from './Home.module.scss';
import BackgroundImage from "../BackgroundImage";
import BackgroundVideo from "../BackgroundVideo";
import TagLine from './HomeTagline';
import Logo from './HomeLogo';
import jpg from './background.jpg';
import webp from './background.webp';
import LanguagePicker from "../LanguagePicker";
import { After, Before, Content, Outer } from '../ScrollableSite';
import {store} from "../App/store";

function Home({...props}, ref) {
  const { state } = useContext(store);
  const [tagLinePrefix, setTagLinePrefix] = useState('Schimmer Music Production');
  const [tagLine, setTagLine] = useState(<>It is<br />all about<br />the music</>);

  const content = state.content?.home || {};
  const alineas = content?.alineas || [];

  const bgvid = useMemo(() => {
    const videos = alineas.map((alinea) => {
      return {
        video: 'https://smp.nl' + alinea.alinea_video,
        prefix: '',
        tagLine: alinea.title,
      };
    });
    const onTagLineChange = (prefix, tagLine) => {
      setTagLinePrefix(prefix);
      setTagLine(tagLine);
    };
    if (!videos.length) {
      return null;
    }
    return (
      <BackgroundVideo
        items={videos}
        onTaglineChange={onTagLineChange} />
    );
  }, [alineas]);

  return (
    <Outer className={styles.outer}>
      <Before />
      <Content ref={ref}>
        <BackgroundImage src={jpg} alt="" grid={false} srcs={[
          {
            type: 'image/webp',
            srcset: webp,
          },
        ]} />

        {bgvid}

        <div className={styles.Home}>
          <Logo />
          <TagLine prefix={tagLinePrefix} overlayEffect={false}>
            {tagLine}
          </TagLine>
        </div>
        <LanguagePicker className={styles.language} />
      </Content>
      <After className={styles.after} />
    </Outer>
  );
}

export default forwardRef(Home);
