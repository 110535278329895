import React from 'react';
import styles from './ScrollableSite.module.scss';

function After({children, className, ...props}) {
  return (
    <div className={`${styles.after} ${className}`.trimRight()} {...props}>
      {children}
    </div>
  );
}

export default After;
