import React from "react";
import styles from '../Video/Video.module.scss';

function VimeoVideo({
  src,
  title,
  children = null,
  className = '',
  ratio = '16x9',
  ...props
}) {
  const ratioClass = (ratio && styles[`ratio${ratio}`]) || null;
  const paddingBottom = `calc(${ratio.split('x')[1] || 1} / ${ratio.split('x')[0] || 1} * 100%)`;

  const classNames = [
    styles.videoContainer,
    styles.ratio,
    ratioClass,
    className,
  ].filter(Boolean).join(' ');

  return (
    <div className={classNames}
      style={{
        paddingBottom: ratioClass ? null : paddingBottom,
      }}>
      <iframe
        {...props}
        src={`${src}?color=ea5c1c&title=0&byline=0&portrait=0`}
        title={title || 'Embedded video'}
        frameBorder="0"
        loading="lazy"
        allowFullScreen={true}
        className={styles.video}
        />
    </div>
  );
}

export default VimeoVideo;
