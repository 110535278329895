import React, { forwardRef } from 'react';
import styles from './ScrollableSite.module.scss';

function Content({children, className, ...props}, ref) {
    return (
    <div className={`${styles.content} ${className || ''}`.trimRight()} {...props} ref={ref}>
      {children}
    </div>
  );
}

export default forwardRef(Content);
