import React, {useEffect, useState} from "react";
import styles from './SearchInput.module.scss';
import useTranslations from "../../hooks/useTranslations";

function SearchInput({children, ...props}) {
  const __ = useTranslations();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (children || children === 0) {
      setValue(String(children));
    } else {
      setValue('');
    }
  }, [children]);

  function onInput(ev) {
    setValue(ev.currentTarget.value);
    if (typeof props.onInput === 'function') {
      ev.value = ev.currentTarget.value;
      props.onInput(ev);
    }
  }

  function onSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (typeof props.onSubmit === 'function') {
      ev.value = value;
      props.onSubmit(ev);
    }
  }

  return (
    <form className={styles.searchBar} onSubmit={onSubmit}>
      <input type="search" name="searchTerm" placeholder={props.value || __('Zoeken')}
        value={value} onChange={onInput} />
      <span className={styles.visuallyHidden}>
        <button type="submit">{__('Zoeken')}</button>
      </span>
    </form>
  );

}

export default SearchInput;
