import React from "react";
import styles from './Contact.module.scss';
import {ReactComponent as Facebook} from './icons/Facebook.svg';
import {ReactComponent as Twitter} from './icons/Twitter.svg';
import {ReactComponent as Instagram} from './icons/Instagram.svg';
import {ReactComponent as LinkedIn} from './icons/Linkedin.svg';

function Socials() {
  return (
      <div>
        <ul className={styles.Socials}>
          <li><a href="https://www.facebook.com/SMPAmsterdam" target="_blank" rel="noopener noreferrer"><Facebook/></a></li>
          <li><a href="https://twitter.com/SMP_Amsterdam" target="_blank" rel="noopener noreferrer"><Twitter/></a></li>
          <li><a href="https://www.instagram.com/smpamsterdam/" target="_blank" rel="noopener noreferrer"><Instagram/></a></li>
          <li><a href="https://www.linkedin.com/company/smp-amsterdam/" target="_blank" rel="noopener noreferrer"><LinkedIn/></a></li>
        </ul>
      </div>
  );
}

export default Socials;
