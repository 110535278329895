// store.js
import React, {createContext, useReducer} from 'react';

const initialState = {
  lang: 4,
  langName: 'Nederlands',
  translations: {},
  pageScrollingEnabled: true,
  meta: <>
    <title>SMP</title>
  </>,
  metaRaw: null,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'setLanguage':
        return {
          ...state,
          lang: action.value,
          langName: action.name,
        };
      case 'setRoutes':
        return {
          ...state,
          routes: action.value,
        };
      case 'setTranslations':
        return {
          ...state,
          translations: action.value,
        };
      case 'setContent':
        return {
          ...state,
          content: action.value,
        };
      case 'setPageScrollingEnabled':
        return {
          ...state,
          pageScrollingEnabled: !!action.value,
        };
      case 'setMeta':
        const meta = action.value;
        return {
          ...state,
          meta,
        };
      case 'setSearchTerm':
        return {
          ...state,
          searchTerm: action.value,
        };
      case 'setAudio':
        return {
          ...state,
          audio: action.value,
        };
      default:
        throw new Error('Unknown action');
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }
