import React, {forwardRef, useRef, useEffect, useContext} from 'react';
import { After, Before, Content, Outer } from '../ScrollableSite';
import styles from './ShowreelPage.module.scss';
import {CSSTransition} from "react-transition-group";
import {store} from "../App/store";
import Video from "../Video";

function ShowreelPage({activeSlide, name: slideName, children, slideDirection, ...props}, ref) {
  const { state } = useContext(store);

  const content = state.content?.showreel || {};
  const video = useRef(null);

  const onShowreelPage = (props.isInView);

  useEffect(() => {
    if (!onShowreelPage && video.current) { // activeSlide !== slideName && video.current && !video.current.paused) {
      video.current.pause();
      video.current.currentTime = 0;
    }
  }, [onShowreelPage]);

  const afterDirection = slideDirection > 0 ? styles.animationLtr : styles.animationRtl;

  const videoSrc = content?.alineas?.[0]?.alinea_video || null;
  const poster = content?.alineas?.[0]?.alinea_img || null;
  const titles = (content?.alineas || []).slice(0, 4).map((alinea, i) => {
    const cls = ['a', 'b', 'c', 'd']
    return (
      <div className={styles[cls[i]]} key={i}>
        {alinea.title}
      </div>
    );
  });

  return (
    <Outer className={styles.outer}>
      <Before className={styles.before} />
      <Content className={styles.content} ref={ref}>
        {videoSrc && (
        <CSSTransition in={props.active}
          timeout={3000}
          classNames="showreelpage-content"
          mountOnEnter={false}
          unmountOnExit={false}
          appear={false}>
          <div className={styles.contentFade}>
            <Video
              src={`https://smp.nl/${videoSrc}`}
              poster={`/api${poster}`} />
          </div>
        </CSSTransition>
        )}

        {children}
      </Content>
      <CSSTransition in={props.active}
        timeout={2500}
        classNames="showreelpage-bg"
        mountOnEnter={false}
        unmountOnExit={false}
        appear={false}>
        <After className={`${styles.after}`}>
          <div className={afterDirection}>
            {titles}
            <div className={styles['bg-large']} />
          </div>
        </After>
      </CSSTransition>
    </Outer>
  );
}

export default forwardRef(ShowreelPage);
