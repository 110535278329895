import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './TeamPage.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import prevSvg from './prev.svg';
import nextSvg from './next.svg';
import Audio from "../Audio";
import {store} from "../App/store";

function TeamMember({image, text, title, name, trackId, currentSlide}) {
  const canvas = useRef();

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    const img = new Image();
    img.onload = () => {
      try {
        img.onload = null;
        img.onerror = null;
        const curCanvas = canvas.current;
        curCanvas.width = img.width;
        curCanvas.height = img.height;
        const ctx = curCanvas.getContext('2d');
        ctx.filter = 'blur(5px)';
        ctx.drawImage(img, 0, 0);
      } catch (err) {}
    }
    img.onerror = () => {
      img.onload = null;
      img.onerror = null;
    };
    img.src = image;
    return () => {

    };
  }, [image, canvas]);

  return (
    <div className={styles.slide} style={{
      backgroundImage: `url('${image}')`,
    }}>
      <canvas ref={canvas} className={styles.slideBlurredBg} />
      <div className={styles.innerWidthBreaker}>
        <div className={styles.title}>
          {title ? <h2>{title}</h2> : null}
          <h1>{name}</h1>
        </div>
        <div className={styles.text}>
          {trackId ? <Audio small={true} trackId={trackId} stopWhenPropChanges={currentSlide} /> : null}
          <div dangerouslySetInnerHTML={{__html: text}} />
        </div>
      </div>
    </div>

  );
}

function TeamPage({children, ...props}) {

  const { state } = useContext(store);
  const [isMobileViewport, setIsMobileViewport] = useState(true);

  const [ currentSlide, setCurrentSlide ] = useState(null);

  const content = state.content?.over_ons || {};

  const renderPrev = (click, enabled, label) => {
    return <img src={prevSvg} alt={label || "Prev"} aria-label={label} onClick={click}
      className={`${styles.prev} ${enabled ? styles.enabled : styles.disabled}`} />
  };
  const renderNext = (click, enabled, label) => {
    return <img src={nextSvg} alt={label || "Next"} aria-label={label} onClick={click}
      className={`${styles.next} ${enabled ? styles.enabled : styles.disabled}`} />
  };
  const renderStatus = (i, total) => {
    return <div className={styles.status}>
      <strong>{i < 10 ? `0${i}` : i}</strong>/<span>{total < 10 ? `0${total}` : total}</span>
    </div>
  };

  const items = (content.team || []).map(({id, data, ...alinea}) => {
    return {
      id: id,
      image: '/api/' + data.img,
      text: data.text,
      title: data.func,
      name: data.name,
      trackId: data.audio,
    };
  });

  useEffect(() => {
    const mq = window.matchMedia('(min-width: 1024px)');
    function cb(ev) {
      if (isMobileViewport !== !ev.matches) {
        setIsMobileViewport(!ev.matches);
      }
    }
    cb(mq);
    mq.addEventListener('change', cb);
    return () => {
      mq.removeEventListener('change', cb);
    }
  }, [isMobileViewport]);

  const teamMembers = items.map((props) => (
    <TeamMember {...props} key={props.id || props.name} currentSlide={currentSlide} />
  ));

  return (
    <>
      <Carousel renderArrowPrev={renderPrev} renderArrowNext={renderNext}
        showThumbs={false} statusFormatter={renderStatus}
        centerMode
        transitionTime={1000}
        infiniteLoop
        centerSlidePercentage={isMobileViewport ? 100 : 55}
        onChange={(index, item) => {
          setCurrentSlide(index);
        }}
        className={styles.carousel}>
        {teamMembers}
      </Carousel>
    </>
  );

}

export default TeamPage;
