import React from "react";
import styles from './HomeTagline.module.scss';

function HomeTagline({prefix, children, overlayEffect, ...props}) {
  const cls = [
    styles.Tagline,
    overlayEffect === false ? styles.noOverlayEffect : null
  ].filter(Boolean);

  return (
    <div className={cls.join(' ')}>
      <h1>{prefix}</h1>
      <div className={styles.Container}>
        <h2>{children}</h2>
        <h2 className={styles.Clone}>{children}</h2>
      </div>
    </div>
  );
}

export default HomeTagline;
