import React, {useContext} from 'react';
import styles from './StudioPage.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import prevSvg from './prev.svg';
import nextSvg from './next.svg';
import {store} from "../App/store";

function StudioPage({children, ...props}) {
  const { state } = useContext(store);

  const content = state.content?.over_ons || {};

  const renderPrev = (click, enabled, label) => {
    return <img src={prevSvg} alt={label || "Prev"} aria-label={label} onClick={click}
      className={`${styles.prev} ${enabled ? styles.enabled : styles.disabled}`} />
  };
  const renderNext = (click, enabled, label) => {
    return <img src={nextSvg} alt={label || "Next"} aria-label={label} onClick={click}
      className={`${styles.next} ${enabled ? styles.enabled : styles.disabled}`} />
  };
  const renderStatus = (i, total) => {
    return <div className={styles.status}>
      <strong>{i < 10 ? `0${i}` : i}</strong>/<span>{total < 10 ? `0${total}` : total}</span>
    </div>
  };

  const items = (content.alineas || []).slice(1).map((alinea) => {
    return (
      <div className={styles.slide} key={alinea.id}>
        <div className={styles.text}>
          <h1>{alinea.title}</h1>
          <div dangerouslySetInnerHTML={{__html: alinea.txt.replace('<img ', '<img style="display:none" ')}} />
        </div>
        <div className={styles.frame}>
          <img src={`/api/${alinea.alinea_img}`} alt="" />
          {/*<iframe title="360-1" src={`${process.env.PUBLIC_URL}/assets/360/bar-keuken-lounge/index.html`} />*/}
        </div>
      </div>
    );
  });

  const isMobileViewport = false;

  // not swipable due to bugs (vertical scrolling = swipe to next slide...)
  return (
    <>
      <Carousel renderArrowPrev={renderPrev} renderArrowNext={renderNext}
        showThumbs={false} statusFormatter={renderStatus}
        centerMode
        transitionTime={1000}
        infiniteLoop
        swipeable={false}
        swipeScrollTolerance={100}
        centerSlidePercentage={isMobileViewport ? 100 : 75}
        className={styles.carousel}>
        {items}
      </Carousel>
    </>
  );

}

export default StudioPage;
