import React, {useEffect, useRef, useState} from "react";
import Grid from '../BackgroundImage/Grid';
import styles from './BackgroundVideo.module.scss';

/**
 * @param {HTMLVideoElement} el
 */
function pause(el) {
  Promise.resolve(el.pause()).catch(() => {});
}

/**
 * @param {HTMLVideoElement} current
 * @param {HTMLVideoElement} next
 */
async function onEndedFn(current, next) {
  // Check if the other video has finished loading, switch to it if so.
  // const n = current.nextElementSibling ? 1 : 2;
  const ready = next.readyState !== 0;
  if (!ready) {
    current.currentTime = 0;
    current.play();
    return;
  }
  pause(current);

  // Swap out text here

  await new Promise((resolve) => {
    setTimeout(resolve, 150);
  });

  current.classList.remove(styles.current);
  current.classList.add(styles.next);

  next.classList.remove(styles.next);
  next.classList.add(styles.current);

  // Start video 2.
  next.currentTime = 0;
  Promise.resolve(next.play())
    .catch(e => console.log('Handoff err', e));

  current.currentTime = 0;
  pause(current); // just in case.

  return true;
}


function BackgroundVideo({
  src,
  //srcs,
  alt = "",
  grid = true,
  items = [],
  //tagLines,
  onTaglineChange,
  ...props
}) {
  const video1 = useRef();
  const video2 = useRef();
  const videoIndex = useRef(1);

  const [autoPlay, setAutoPlay] = useState(window.location.search.indexOf('no-vid')===-1);

  const [video1Src, setVideo1Src] = useState(items[0].video);
  const [video2Src, setVideo2Src] = useState(items[1].video);

  useEffect(() => {
    if (!(video1.current instanceof HTMLVideoElement)) {
      return;
    }
    const vid = video1.current;
    function handler() {
      onTaglineChange(items[0].prefix, items[0].tagLine);
      vid.parentNode.classList.add(styles.loaded);
      vid.removeEventListener('playing', handler);
    }
    vid.addEventListener('playing', handler);

    return () => {
      vid.removeEventListener('playing', handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!(video1.current instanceof HTMLVideoElement)) {
      return;
    }
    if (!(video2.current instanceof HTMLVideoElement)) {
      return;
    }
    const vid1 = video1.current;
    const vid2 = video2.current;

    function handler1() {
      setAutoPlay(false);
      video1.current.removeAttribute('autoplay');
      video1.current.autoplay = false;
      onEndedFn(video1.current, video2.current).then(didSwapVideo => {
        if (didSwapVideo) {
          const tagLine = items[videoIndex.current];
          let nextVideo = items[++videoIndex.current]?.video;
          if (!nextVideo) {
            nextVideo = items[0].video;
            videoIndex.current = 0;
          }
          setVideo1Src(nextVideo);
          onTaglineChange(tagLine.prefix, tagLine.tagLine);
        }
      });
    }

    function handler2() {
      onEndedFn(video2.current, video1.current).then(didSwapVideo => {
        if (didSwapVideo) {
          const tagLine = items[videoIndex.current];
          let nextVideo = items[++videoIndex.current]?.video;
          if (!nextVideo) {
            nextVideo = items[0].video;
            videoIndex.current = 0;
          }
          setVideo2Src(nextVideo);
          onTaglineChange(tagLine.prefix, tagLine.tagLine);
        }
      });
    }

    vid1.addEventListener('ended', handler1);
    vid2.addEventListener('ended', handler2);

    return () => {
      vid1.removeEventListener('ended', handler1);
      vid2.removeEventListener('ended', handler2);
    }

  }, [video1, video2, videoIndex, items, onTaglineChange]);

  return (
    <>
      <div className={styles.bg}>
        <video ref={video1} className={styles.current}
            preload="auto"
            src={video1Src}
            autoPlay={autoPlay ? 'autoplay' : ''}
            playsInline="playsinline"
            muted={true} controls={false} loop={false} />
        <video ref={video2} className={styles.next}
            preload="auto"
            src={video2Src}
            playsInline="playsinline"
            muted={true} controls={false} loop={false} />
      </div>
      {grid && <Grid />}
    </>
  );
}

export default BackgroundVideo;
