import React, {} from 'react';
import styles from './LightboxPage.module.scss';
import { useLocation } from 'react-router-dom'
import HomeLogo from '../Home/HomeLogo';
import Back from "./Back";
import bgAlt1 from './bg-alt1.jpg';
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function LightboxPage({children, backTo, ...props}) {

  const urls = useTranslatableUrls();
  const location = useLocation();
  const pathName = location.pathname;

  const home = urls('home');

  let bg = null;
  if (pathName.includes(urls('licensing'))) {
    bg = bgAlt1;
  }
  if (pathName.includes(urls('portfolio_case'))) {
    bg = false;
  }
  if (pathName.includes(urls('team'))) {
    bg = false;
  }

  let backTo2 = backTo || home;
  if (pathName.includes(urls('portfoliocategorien'))) {
    backTo2 = urls('portfoliocategorien');
  }

  return (
    <>
      <div className={styles.container} style={{
        backgroundImage: bg === null ? null : (bg ? `url('${bg}')` : 'none')
      }}>
        <div className={styles.header}>
          <div>
            <HomeLogo className={styles.logo} link={backTo2 || home} />
          </div>
          <div>
            <Back to={backTo2} />
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </>
  );

}

export default LightboxPage;
