import React, {useEffect, useState, useContext, useMemo} from "react";
import styles from './MobileNav.module.scss';
import logo from './SMP-logo-on-gray.png';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Hamburger } from './hamburger.svg';
import { store } from '../App/store';
import LanguagePicker from "../LanguagePicker";

function MobileNav({children, activeIndex, aboutUsSubmenu, ...props}) {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useContext(store);

  const editedAboutUsSubmenu = useMemo(() => {
    return React.cloneElement(aboutUsSubmenu, {
      className: `${aboutUsSubmenu.props.className || ''} ${styles.submenu}`,
    })
  }, [aboutUsSubmenu]);

  /*
   * This component uses a custom 'activeIndex' rather than
   * <NavLink> so that even if there's a pop-up window, the menu item
   * will stay active.
   */

  function openMenu(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    setIsOpen(true);
  }

  function closeMenu(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    setIsOpen(false);
  }

  function hideMenuAndNavigate(ev) {
    // Allow propagation and don't stop default.
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch({type: 'setPageScrollingEnabled', value: !isOpen});
  }, [isOpen, dispatch]);

  const classNames = [
    styles.container,
    isOpen ? styles.open : styles.closed,
  ].filter(Boolean).join(' ');

  return (
    <>
      <div className={classNames} {...props}>
        <div className={styles.logo}>
          <img src={logo} alt={"SMP Logo"} title={""} />
        </div>
        <div className={styles.close} onClick={closeMenu}>
          <Close />
        </div>

        <LanguagePicker className={styles.language} />

        {children.map((child, i) => {
          const isAboutUs = i === 1;
          const classNames = [
            styles.child,
            i + 1 === children.length ? styles.last : null,
            activeIndex === i ? styles.active : null,
          ].filter(Boolean).join(' ');
          return <div className={classNames} key={i} onClick={hideMenuAndNavigate}>
            {child}
            {isAboutUs ? editedAboutUsSubmenu : null}
          </div>
        })}
      </div>
      <div className={styles.opener} onClick={openMenu}>
        <Hamburger />
      </div>
    </>
  );
}

export default MobileNav;
