import React, {cloneElement} from "react";
import styles from './ScreenEffectText.module.scss';

function ScreenEffectText({
  children = null,
  className = '',
  ...props
}) {

  /*

  <div className={styles.Container}>
        <h2>{children}</h2>
        <h2 className={styles.Clone}>{children}</h2>
      </div>
   */

  const outerClasses = [className, styles.Container].filter(Boolean).join(' ');

  return (
    <div className={outerClasses} {...props}>
      {cloneElement(children, {
        className: [
          children.props.className,
          styles.Original,
        ].filter(Boolean).join(' ')
      })}
      {cloneElement(children, {
        className: [
          children.props.className,
          styles.Clone,
        ].filter(Boolean).join(' ')
      })}
    </div>
  );
}

export default ScreenEffectText;
