import React, {useRef} from "react";
import { Link } from "react-router-dom";
import styles from './MainNav.module.scss';
import grey from './audiotrackbar-grey.png';
import orange from './audiotrackbar-orange.svg';

function SimpleAudioTrackbar({index, ...props}) {

  const container = useRef(null);
  //0.05434782609

  // polygon(50% 0, 100% 0, 100% 100%, 50% 100%)
  // polygon(0 0, 50% 0, 50% 100%, 0 100%);

  const indexes = [
    1,
    18.5,
    36.7,
    54.4,
    72.7,
    90.2,
    100,
  ];

  const perc1 = indexes[index] || 0;
  const perc = `var(--p, ${perc1}%)`;
  const clip1 = `polygon(0 0, ${perc} 0, ${perc} 100%, 0 100%)`;
  const clip2 = `polygon(${perc} 0, 100% 0, 100% 100%, ${perc} 100%)`;

  return (
    <div className={styles.simpleAudioTrackbar} ref={container} style={{'--p': ''}}>
      <img src={orange} alt='' style={{clipPath: clip1}} />
      <img src={grey} alt='' style={{clipPath: clip2}} />
    </div>
  );

}

function MainNav({children, activeIndex, prevLink, nextLink, ...props}) {
  const numItems = children.length;

  /*
   * This component uses a custom 'activeIndex' rather than
   * <NavLink> so that even if there's a pop-up window, the menu item
   * will stay active.
   */

  const prevClasses = [
    styles.prev,
    activeIndex === 0 ? styles.disabled : null,
  ].filter(Boolean).join(' ');

  const nextClasses = [
    styles.next,
    activeIndex === numItems - 1 ? styles.disabled : null,
  ].filter(Boolean).join(' ');

  return (
    <div className={styles.container} {...props}>
      {children.map((child, i) => {
        const classNames = [
          styles.child,
          i + 1 === children.length ? styles.last : null,
          activeIndex === i ? styles.active : null,
        ].filter(Boolean).join(' ');
        return <div className={classNames} key={i}>
          {child}
        </div>
      })}
      <div className={styles.indicator}>
        <SimpleAudioTrackbar index={activeIndex} />
      </div>
      {prevLink ? (
      <Link to={`${process.env.PUBLIC_URL&&''}${prevLink}`} className={prevClasses}><span className={styles.hidden}>Previous</span></Link>
        ) : null}
      {nextLink ? (
      <Link to={`${process.env.PUBLIC_URL&&''}${nextLink}`} className={nextClasses}><span className={styles.hidden}>Next</span></Link>
        ) : null}
    </div>
  );
}

export default MainNav;
