import React from "react";
import styles from './Library.module.scss';
import Audio from "../Audio";

// Bedoeling is dat de Tile component props ontvangt, zodat deze herbruikbaar is :)

function AlbumTiles({soundCloudTracks = [], bulletPoints, disabled, ...props}) {

  return (
      <div className={styles.tiles} >
        <div className={styles.list}>
          <ul>
            {bulletPoints.map((alinea) => {
              return (<li key={alinea.id}>{alinea.title}</li>);
            })}
          </ul>
        </div>

        {soundCloudTracks.map(({id: trackId, poster}, i) => (
          <div className={`${styles.square} ${i === 0 ? styles.first : ''}`} key={i}>
            <Audio trackId={trackId} className={styles.audioPlayerTile} poster={poster}
              disabled={disabled} />
          </div>
        ))}
      </div>
  );
}

export default AlbumTiles;
