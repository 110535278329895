import React from "react";
import logo from './SMP-logo.png';
import styles from './Home.module.scss';
import {Link} from "react-router-dom";

function HomeLogo({className, link = null, ...props}) {
  const classNames = `${className || ''} ${styles.LogoContainer}`;

  const img = <img src={logo} alt="SMP logo" title="" width="115" height="102" />;

  return (
    <div className={classNames} {...props}>
      {link ? <Link to={link}>{img}</Link> : img}
    </div>
  );

}

export default HomeLogo;
