import React from 'react';
import styles from './ScrollableSite.module.scss';

function Before({children, className, ...props}) {
  return (
    <div className={`${styles.before} ${className}`.trimRight()} {...props}>
      {children}
    </div>
  );
}

export default Before;
