import React from "react";
import styles from './Contact.module.scss';
// import {Link} from "react-router-dom";

function Contactlinks({content, ...props}) {
  return (
      <div className={styles.links} dangerouslySetInnerHTML={{__html: content.txt}}/>
  );
}

export default Contactlinks;
