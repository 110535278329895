import React, {useState, useRef} from "react";
import styles from './ContentPage.module.scss';
import useSeo from "../../hooks/useSeo";
import { useHistory } from 'react-router-dom';
import useTranslations from "../../hooks/useTranslations";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";
// import ContentBlock from "./ContentBlock";

function NewsletterForm({html, ...props}) {
  const __ = useTranslations();
  const urls = useTranslatableUrls();
  const rnd = 'newsletter-form-element-' + Math.random();

  return (<form action={"https://www.smp.nl/api/nieuwsbriefemail"} method='post'>
    <label htmlFor={rnd} style={{display:'none'}}>
      {__('Emailadres')}:
    </label>
    &nbsp;
    <input type='hidden' name='redir' value={urls('nieuwsbrief_ingeschreven')} />
    <input id={rnd} type='email' name='email' placeholder={__('Emailadres')} className={styles.text} />
    &nbsp;
    <button type='submit' className={styles.submit}>
      {__('Versturen')}
    </button>
  </form>);
}


function ContactForm({html, ...props}) {
  const [rnd] = useState('contact-form-element-' + Math.random());
  const [error, setError] = useState(null);
  const form = useRef(null);

  const __ = useTranslations();
  const urls = useTranslatableUrls();
  const history = useHistory();

  function sendForm(ev) {
    ev.preventDefault();
    const data = new FormData(form.current);
    setError(null);
    fetch(form.current.getAttribute('action'), {
      method: 'POST', body: data,
    }).then(res => res.json()).then((res) => {
      if (!res.success && res.error) {
        setError(String(res.error));
        return;
      } else if (!res.success) {
        setError('Onbekende fout');
        return;
      }
      history.push(urls('contactformulier_ingezonden'));
    }).catch((err) => {
      setError('Onbekende fout');
      console.error(err);
    });
  }

  return (<form action={"/api/contactformulier"} method='post' className={styles.contactForm} onSubmit={sendForm} ref={form}>
    <input type='hidden' name='redir' value={urls('nieuwsbrief_ingeschreven')} />

    <label htmlFor={`${rnd}-name`}>
      {__('Naam')} *:
    </label>
    &nbsp;
    <input id={`${rnd}-name`} type='text' name='name' placeholder={__('Naam')} className={styles.text} />

    <br />

    <label htmlFor={`${rnd}-email`}>
      {__('Emailadres')} *:
    </label>
    &nbsp;
    <input id={`${rnd}-email`} type='email' name='email' placeholder={__('Emailadres')} className={styles.text} />

    <br />

    <label htmlFor={`${rnd}-phone`}>
      {__('Telefoonnummer')}:
    </label>
    &nbsp;
    <input id={`${rnd}-phone`} type='text' name='phone' placeholder={__('Telefoonnummer')} className={styles.text} />

    <br />

    <label htmlFor={`${rnd}-question`}>
      {__('Uw vraag')} *:
    </label>
    &nbsp;
    <textarea id={`${rnd}-question`} name='question' className={styles.textarea} />

    <br />

    &nbsp;
    <button type='submit' className={styles.submit}>
      {__('Versturen')}
    </button>

    <em className={styles.reqMsg}>* = {__('verplicht')}</em>

    <br />

    {error && <strong className={styles.errMsg}>{error}</strong>}
  </form>);
}

function ContentItem({
  title, html, image, alignImage, secondary, className,
  ...props
}) {
  const classNames = [
    className,
    styles.contentItem,
    alignImage === 'R' && styles.rtl,
    alignImage === 'F' && styles.fullWidth,
    props.pageTitle && styles.hasPageTitle,
    image ? styles.hasImage : styles.hasNoImage,

  ].filter(Boolean).join(' ');

  let reactContent;

  if ((html || '').indexOf('{{formulier}}') !== -1) {
    reactContent = <NewsletterForm html={html} />;
  }

  if ((html || '').indexOf('{{contactformulier}}') !== -1) {
    reactContent = <ContactForm html={html} />
  }

  // second div for big title, hide with css if no image selected (maybe not needed)
  return <div className={classNames}>
    {!props.pageTitle ? null : <h1 className={styles.primaryTitle}>
      {props.pageTitle}
    </h1>}
    <div className={styles.primary}>
      {title ? <h2>{title}</h2> : null}
      {reactContent || (html ? <div dangerouslySetInnerHTML={{__html: html}} /> : null)}
    </div>
    <div className={styles.secondary}>
      {image ? <img src={`${image}`} alt="" /> : null}
    </div>
  </div>;
}

function ContentPage({children, title, seo, items, ...props}) {
  // const firstItemHasTitle = title && (!items[0]?.image || items[0]?.alignImage === 'F');
  useSeo(seo || null);

  return (
    <div {...props}>
      {(items || []).map((item, i, items) => {
        const itemProps = {};
        if (i === 0) {
          itemProps.pageTitle = item.title || title;
          itemProps.isFirst = true;
        } else if (i === items.length - 1) {
          itemProps.isLast = true;
        }
        const mapping = {
          'txt': 'html',
          'alinea_img_pos': 'alignImage',
          'alinea_img': 'image',
        };
        for (const [backendKey, frontendKey] of Object.entries(mapping)) {
          if (typeof item[backendKey] !== undefined && typeof item[frontendKey] === 'undefined') {
            let value = item[backendKey];
            if (value && backendKey === 'alinea_img' && !value.startsWith('http')) {
              value = 'https://smp.nl/' + value;
            }
            item[frontendKey] = value;
          }
        }
        return <ContentItem key={item.id || i} {...item} {...itemProps} />
      })}
      {children ? <div className={styles.afterContentItem}>{children}</div> : null}
    </div>
  );
}

export default ContentPage;
