import {useContext} from "react";
import {store} from "../components/App/store";

function useTranslatableUrls() {
  const { state } = useContext(store);
  return (slug) => {
    if (!state.routes || !Object.keys(state.routes).length) {
      return;
    }
    const found = Object.values(state.routes).find((route) => {
      return route.slug === slug && route.lang === state.lang;
    });
    if (!found) {
      return Object.values(state.routes).find((route) => {
        return route.slug === slug;
      })?.route || undefined;
    }
    return found.route || undefined;
  };
}

export default useTranslatableUrls;
