import React from 'react';
import styles from './ScrollableSite.module.scss';

function Outer({children, className, ...props}) {
  return (
    <div className={`${styles.outer} ${className}`.trimRight()} {...props}>
      {children}
    </div>
  );
}

export default Outer;
