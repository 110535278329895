import React from 'react';
import styles from './LightboxPage.module.scss';
import back from './back.svg';
import {Link} from "react-router-dom";
import useTranslations from "../../hooks/useTranslations";

function Back({children, to, className, ...props}) {
  const __ = useTranslations();

  const classNames = [
    className,
    styles.back,
  ].filter(Boolean).join(' ');

  return (
    <Link to={`${process.env.PUBLIC_URL&&''}${to || '/'}`} className={classNames}>
      <img src={back} alt="" />
      {children || __('Ga terug')}
    </Link>
  );
}

export default Back;
