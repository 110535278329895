import React, {useContext, useEffect, isValidElement} from "react";
import {store} from "../components/App/store";

function useSeo(seo) {
  const { dispatch } = useContext(store);
  let elements;

  if (isValidElement(seo)) {
    elements = seo;
  } else if (seo === null) {
    // no-op
  } else {
    // Create React elements.
    elements = <>{Object.entries(seo).map(([name, content]) => {
      if (name === 'title') {
        return <title key="title">{content}</title>;
      }
      return <meta name={name} content={content} key={`meta-${name}`} />;
    })}</>;
  }

  const isDifferent = JSON.stringify(seo);

  useEffect(() => {
    if (seo === null) {
      return; // no-op
    }
    dispatch({
      type: 'setMeta',
      value: elements,
    });
    // This is absolutely not the way to do this, but time constraints.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDifferent]);
}

export default useSeo;
