import React, {useContext, useEffect, useState} from "react";
import styles from './Audio.module.scss';
import {ReactComponent as VolumeIcon} from './audio.svg';
import { withRouter } from "react-router-dom";
import {store} from "../App/store";

function AudioInner({
  src,
  trackId,
  poster,
  small = false,
  children = null,
  className = '',
  stopWhenPropChanges = null,
  ...props
}) {
  const { state, dispatch } = useContext(store);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVolumeControlOpen, setIsVolumeControlOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [widget, setWidget] = useState(null);
  const [stopWhenPropChangesPrevious, setStopWhenPropChangesPrevious] = useState(stopWhenPropChanges);

  function createWidgetInstance(ev) {
    const iframe = ev.currentTarget;
    const volumeControl = iframe.parentNode.querySelector('input[type=range]');
    const SC = window.SC;
    if (!SC || !iframe) {
      throw new Error('SoundCloud Widget API not loaded');
    }
    const widget = SC.Widget(iframe);
    if (!widget.SC) {
      widget.SC = SC;
    }
    setWidget(widget);
    const volumeVal = parseInt(volumeControl?.value, 10) || 75;
    widget.setVolume(volumeVal);
    widget.getDuration((duration) => {
      setDuration(duration);
    })
  }

  const currentPlayingAudio = state.audio;
  useEffect(() => {
    if (!widget) {
      return;
    }
    if (currentPlayingAudio !== trackId) {
      setIsPlaying(false);
      widget.pause();
      widget.seekTo(0);
      setProgress(0);
    }
  }, [widget, currentPlayingAudio, trackId]);

  useEffect(() => {
    if (!widget) {
      return;
    }

    // Listen to nav events
    const unbind = props.history.listen((newLocation) => {
      // Option 1: If the new location doesn't match the current, stop.
      /*if (!props.match || !matchPath(newLocation.pathname, props.match)) {
        setIsPlaying(false);
        widget.pause();
      }*/
      // Option 2: always just stop.
      setIsPlaying(false);
      widget.pause();
      widget.seekTo(0);
      setProgress(0);
    });

    if (stopWhenPropChangesPrevious !== stopWhenPropChanges) {
      setIsPlaying(false);
      widget.pause();
      widget.seekTo(0);
      setProgress(0);
      setStopWhenPropChangesPrevious(stopWhenPropChanges);
    }

    const e = widget.SC.Widget.Events;
    widget.bind(e.PLAY_PROGRESS, (ev) => {
      setProgress(ev.currentPosition);
    });
    widget.bind(e.PLAY, (ev) => {
      dispatch({type: 'setAudio', value: trackId});
      setProgress(ev.currentPosition);
      setIsPlaying(true);
    });
    widget.bind(e.PAUSE, (ev) => {
      setProgress(ev.currentPosition);
      setIsPlaying(false);
    });
    widget.bind(e.FINISH, (ev) => {
      setProgress(ev.currentPosition);
      setIsPlaying(false);
    });

    return () => {
      unbind();
      setIsPlaying(false);
      widget.unbind('SC.Widget.Events.PLAY_PROGRESS');
      widget.unbind('SC.Widget.Events.PLAY');
      widget.unbind('SC.Widget.Events.PAUSE');
      widget.unbind('SC.Widget.Events.FINISH');
    };
  }, [widget, props.history, stopWhenPropChanges, trackId, dispatch, stopWhenPropChangesPrevious]);

  function toggle() {
    if (!widget) {
      console.warn('Widget not yet loaded - can\'t interact yet');
      return;
    }
    widget.toggle();
  }

  function setVolume(volume) {
    if (!widget) {
      console.warn('Widget not yet loaded - can\'t interact yet');
      return;
    }
    widget.setVolume(volume);
  }

  const classNames = [
    styles.audioContainer,
    small ? styles.small : styles.big,
    styles.ratio,
    isPlaying ? styles.isPlaying : styles.isPaused,
    className,
  ].filter(Boolean).join(' ');

  const url = [
    'https://w.soundcloud.com/player/?url=https://api.soundcloud.com/tracks/' + trackId,
    'auto_play=false',
    'buying=false',
    'liking=false',
    'download=false',
    'sharing=false',
    'show_artwork=false',
    'show_comments=false',
    'show_playcount=false',
    'show_user=false',
    'hide_related=false',
    'visual=false',
    'start_track=0',
    'callback=true',
  ].join('&amp;');

  const formatTime = (ms) => {
    const minutes = Math.floor((ms / 1000) / 60);
    const seconds = Math.floor((ms / 1000) % 60);
    const zeroPadded = `0${seconds}`.substr(-2);
    return `${minutes}:${zeroPadded}`;
  };

  if (!trackId) {
    return (
      <>{null}</>
    );
  }

  if (small) {
    return (
      <div className={classNames} onClick={toggle}>
        <iframe src={url}
                frameBorder="no"
                scrolling="no"
                allow="autoplay"
                loading="lazy"
                title={'SoundCloud embed'}
                onLoad={createWidgetInstance} />
      </div>
    );
  }

  function noop(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  }

  function toggleVolumeControl() {
    setIsVolumeControlOpen(cur => !cur);
  }

  return (
    <div className={classNames} onClick={toggle} style={{
      backgroundImage: !poster ? null : `url('${poster}')`,
    }}>
      <div className={styles.audioContent}>
        <div className={styles.timeProgress}>
          <span className={styles.progress}>{formatTime(progress)}</span>
          /
          <span className={styles.duration}>{formatTime(duration)}</span>
        </div>
        <form className={`${styles.volume} ${isVolumeControlOpen ? styles.open : ''}`}
          onSubmit={noop} onClick={noop}>
          <VolumeIcon className={styles.volumeIcon} onClick={toggleVolumeControl} />
          <input type="range" min={0} max={100} step={1} defaultValue={75} onInput={(ev) => {
            const raw = ev.currentTarget.value;
            if (raw || raw === 0 || raw === "0") {
              setVolume(parseInt(raw, 10));
            }
          }}/>
        </form>
        <iframe src={url}
                frameBorder="no"
                scrolling="no"
                allow="autoplay"
                loading="lazy"
                title={'SoundCloud embed'}
                onLoad={createWidgetInstance} />
      </div>
    </div>
  );
}

const Audio = withRouter(AudioInner);

export default Audio;
