import React from "react";
import { Link } from "react-router-dom";
import styles from './Button.module.scss';

function Button({
  src,
  children = null,
  className = '',
  noTextTransform = false,
  highlight = false,
  ...props
}) {

  const classNameValue = [
    styles.button,
    className,
    noTextTransform ? styles.noTextTransform : null,
    highlight ? styles.highlight : null,
  ].filter(Boolean).join(' ');

  if (typeof src !== 'undefined') {
    if (src.indexOf('http') === 0) {
      return (
        <a href={src} className={classNameValue} {...props}>
          {children}
        </a>
      );
    }
    return (
      <Link to={`${process.env.PUBLIC_URL&&''}${src}`} className={classNameValue} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <span className={classNameValue} {...props}>
      {children}
    </span>
  );
}

export default Button;
