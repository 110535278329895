import {useCallback, useContext} from "react";
import {store} from "../components/App/store";

function useTranslations() {
  const { state } = useContext(store);
  return useCallback((original, languageId) => {
    // Get language ID, as specified in the backend.
    const id = (typeof languageId === 'number') ? languageId : state.lang;
    // Find translated string
    const translated = state.translations[original]?.[`${id}`];
    // Return translated or original text.
    return typeof translated !== 'undefined' ? translated : original;
  }, [state.translations, state.lang]);
}

export default useTranslations;
