import React, {useContext, useState} from "react";
import styles from './LanguagePicker.module.scss';
import {NavLink} from 'react-router-dom';
import { store } from '../App/store';

function LanguagePicker({className, ...props}) {
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { state } = useContext(store);
  const { langName, routes } = state;

  const languages = {
    4: 'Nederlands',
    5: 'English',
    6: 'Deutsch',
  };

  // Get links to home page to toggle languages.
  const links = Object.values(routes)
    .filter(route => route.slug === 'home')
    .map((route) => {
      let name = languages[route.lang];
      return name ? (
        <li key={`${route.lang}-${name}`}>
          <NavLink to={route.route}>{name}</NavLink>
        </li>
      ) : null;
    });

  function toggleLanguage() {
    setIsLanguageOpen(cur => !cur);
  }

  const classNames = [
    className,
    styles.languageToggle,
    isLanguageOpen && styles.active,
  ].filter(Boolean).join(' ');

  return (
    <div className={classNames} onClick={toggleLanguage}>
          <span className={styles.currentLanguage}>
            {langName}
          </span>
      <ul className={styles.child}>
        {links}
      </ul>
    </div>
  );
}

export default LanguagePicker;
