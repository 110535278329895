import React from "react";
import Grid from './Grid';
import styles, { container } from './BackgroundImage.module.scss';

function BackgroundImage({
  srcs,
  src,
  alt = "",
  grid = true,
  ...props
}) {

  return (
    <>
      <picture className={styles.bg}>
        {srcs.map(({srcset, ...attrs}) => (
          <source key={srcset} srcSet={srcset} {...attrs} />
        ))}
        <img {...props} alt={alt} src={src} />
      </picture>
      {grid && <Grid />}
    </>
  );
}

export default BackgroundImage;
export { container, Grid };
