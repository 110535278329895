import React, {
  forwardRef,
  useContext, useEffect, useState
} from 'react';
import { useHistory } from "react-router-dom";
import { After, Before, Content, Outer } from '../ScrollableSite';
import styles from './PortfolioCategoriesPage.module.scss';
import { store } from '../App/store';
import Button from "../Button";
import SearchInput from "../SearchInput";
import useTranslations from "../../hooks/useTranslations";
import debounce from "lodash/debounce";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function ucFirst(str) {
  return typeof str !== 'string' ? str : (str[0].toUpperCase() + str.slice(1));
}

function Poster({image = null, onMouseOver = null, type, desc, ...props}) {
  const __ = useTranslations();
  const history = useHistory();
  const title = props.title || type;
  const urls = useTranslatableUrls();

  return (
    <div className={`${styles.poster} ${styles.clickable}`} style={{
      backgroundImage: image ? `url('${image}')` : null
    }} onMouseOver={onMouseOver ? (e) => onMouseOver(e, image) : ()=>{}}
      onClick={() => { history.push(`${urls('portfoliocategorien')}/${type}`) }}>
      <div className={styles.posterFade}>
        <div className={styles.posterInner}>
          <h3>{ucFirst(title)}</h3>
          <p>{desc}</p>
          <Button className={styles.button} src={`${urls('portfoliocategorien')}/${type}`}>{__('Bekijk')}</Button>
        </div>
      </div>
    </div>
  );
}

function PortfolioCategoriesPage({children, active, ...props}, ref) {

  const { state } = useContext(store);
  const urls = useTranslatableUrls();

  const content = state.content?.portfoliocategorien || {};

  const [hoverImage, setHoverImage] = useState(null);
  const [blurredHoverImage, setBlurredHoverImage] = useState(null);
  const { dispatch } = useContext(store);
  const history = useHistory();

  const items = content?.alineas?.map((alinea) => {
    return {
      image: '/api' + alinea.alinea_img,
      desc: alinea.plain_txt || alinea.txt,
      title: alinea.title,
    };
  }) || [];

  function onMouseOver(e, image) {
    if (image) {
      setHoverImage(image);
    }
  }

  const setSearchTerm = function(str) {
    dispatch({type: 'setSearchTerm', value: str});
  }
  const goToSearchPage = function() {
    const url = `${urls('portfoliocategorien')}/search`;
    history.push(url);
  }

  useEffect(() => {
    // Get image, create a canvas, blur it.
    const img = new Image();
    img.onload = () => {
      img.onload = null;
      img.onerror = null;
      const canvas = document.createElement('canvas');
      canvas.width = img.width + 100;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.filter = 'blur(10px)';
      ctx.drawImage(img, 50, 0);
      setBlurredHoverImage(canvas.toDataURL('image/png'));
    };
    img.onerror = () => {
      img.onload = null;
      img.onerror = null;
      setBlurredHoverImage(null);
    };
    img.src = hoverImage;

    return () => {
      setBlurredHoverImage(null);
    }
  }, [hoverImage]);

  const debouncedSetSearchTermInner = debounce(function(value) {
    setSearchTerm(value);
  }, 500);

  return (
    <Outer className={styles.outer}>
      <Before className={styles.before}>
        <div className={styles.decorativeGradient} />
      </Before>
      <Content className={styles.content} ref={ref}>

        <div className={styles.searchBar}>
          <SearchInput onInput={e => debouncedSetSearchTermInner(e.value)} onSubmit={e => {
            setSearchTerm(e.value);
            goToSearchPage();
          }} />
        </div>

        <div className={styles.posterContainer}>
          <div className={styles.bgWrapper} style={{
            backgroundImage: blurredHoverImage ? `url('${blurredHoverImage}')` : null,
          }} />
          <Poster image={items[0]?.image || null} type="idents" desc={
            items[0]?.desc || `De unieke uitstraling en look van uw productie.`
          } title={items[0]?.title || null} onMouseOver={onMouseOver} />
          <Poster image={items[1]?.image || null} type="leadermuziek" desc={
            items[1]?.desc || `De perfecte tune bij uw leaders.`
          } title={items[1]?.title || null} onMouseOver={onMouseOver} />
          <Poster image={items[2]?.image || null} type="speelfilms" desc={
            items[2]?.desc || `Muziek voor films op het grote doek.`
          } title={items[2]?.title || null} onMouseOver={onMouseOver} />
          <Poster image={items[3]?.image || null} type="commercials" desc={
            items[3]?.desc || `Een herkenbare jingle voor uw publiek.`
          } title={items[3]?.title || null} onMouseOver={onMouseOver} />
          <Poster image={items[4]?.image || null} type="series" desc={
            items[4]?.desc || `Producties voor op TV.`
          } title={items[4]?.title || null} onMouseOver={onMouseOver} />
        </div>

        {children}
      </Content>
      <After className={styles.after} />
    </Outer>
  );
}

export default forwardRef(PortfolioCategoriesPage);
